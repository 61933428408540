<style lang="scss" scoped>
/deep/ .comp-agent-detail {
    margin-top: 6vh !important;
    max-width: 450px;
    .el-tag {
        width: 100%;
        height: auto;
        min-height: 28px;
        white-space: normal;
        background-color: #fdfdfd;
    }
}
</style>

<template>
    <div>
        <el-dialog title="代理商详情" custom-class="c-el-dialog comp-agent-detail" 
            :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" label-width="100px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="代理名称">
                            <el-tag type="info">{{ dialogData.name }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="联系人">
                            <el-tag type="info">{{ dialogData.contactName }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="联系电话">
                            <el-tag type="info">{{ dialogData.contactTel }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="商家数量">
                            <el-tag type="info">{{ dialogData.merchantCount }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="设备数量">
                            <el-tag type="info">{{ dialogData.deviceCount }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="分红比例">
                            <el-tag type="info">{{ dialogData.divideRate }}</el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="创建时间">
                            <el-tag type="info">{{ dialogData.creationTime }}</el-tag>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as funAgent from "@/api/agent/index"
export default {
    name: "compAgentDetail",
    data() {
        return {
            showDialog: false,
            dialogData: {}
        }
    },
    methods: {
        show(row) {
            window.$common.fullLoading()
            funAgent.GetAgentInfo({ id: row.id }).then(res => {
                window.$common.closeFullLoading()
                this.dialogData = res
                this.showDialog = true
            })
        },
        _close() {
            this.showDialog = false
        }
    }
}
</script>