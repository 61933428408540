<style lang="scss" scoped>
.page-merchant-list {
    .merchant-select {
        width: 120px;
    }

    .user-avatar {
        width: 50px;
        min-width: 50px;
        height: 50px;
        background-size: cover;
        border-radius: 50%;
        display: block;
        margin-right: 10px;
    }

    .store-info {
        display: flex;
        align-items: center;

        .store-name {
            // flex: 1;
            overflow: hidden;
        }
    }
}
</style>

<template>
    <div class="page-merchant-list">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-button class="mrgb5" type="primary" icon="el-icon-plus" @click="skipToRoute(`/merchant/details`)" size="medium">新增商家</el-button>
                </div>
                <div class="fr">
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange"
                        size="medium" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']"
                        @change="_search" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" align="right">
                    </el-date-picker>
                    <el-select class="merchant-select mrgr5 mrgb5" v-model="listQuery.ProvinceCode" placeholder="请选择省"
                        clearable @change="getDataList()" size="medium">
                        <el-option v-for="item in provinceOptions" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                    <el-select class="merchant-select mrgr5 mrgb5" v-model="listQuery.CityCode" placeholder="请选择市" clearable
                        @change="getDataList()" size="medium">
                        <el-option v-for="item in cityOptions" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                    <el-select class="merchant-select mrgr5 mrgb5" v-model="listQuery.AreaCode" placeholder="请选择区/县"
                        clearable @change="getDataList()" size="medium">
                        <el-option v-for="item in countyOptions" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                    <el-select class="merchant-select mrgr5 mrgb5" v-model="listQuery.CategoryId" placeholder="类别" clearable
                        @change="getDataList()" size="medium">
                        <el-option v-for="item in categories" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                    <el-select class="merchant-select mrgr5 mrgb5" v-model="listQuery.IsActive" placeholder="启用状态" clearable
                        @change="getDataList()" size="medium">
                        <el-option :value="true" label="启用中"></el-option>
                        <el-option :value="false" label="已禁用"></el-option>
                    </el-select>
                    <el-select class="mrgr5 mrgb5" v-model="listQuery.AgentId" filterable remote reserve-keyword
                        placeholder="代理(输入搜索)" :remote-method="renderAgentOptions" clearable @change="getDataList()"
                        size="medium">
                        <el-option v-for="item in agentItems" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="店铺名" clearable v-model="listQuery.Keywords"
                        size="medium"></el-input>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-alert class="mrgb10 colff0" type="error">
                在线设备数量: {{ tableItems.onlineDeviceCount }}
            </el-alert>
            <el-table :data="tableData" border fit highlight-current-row row-key="id" @sort-change="sortChange"
                @selection-change="handleSelectionChange">
                <el-table-column prop="id" label="ID" width="50" type="selection"></el-table-column>
                <el-table-column label="店铺" min-width="160">
                    <template slot-scope="scope">
                        <div class="store-info">
                            <a class="user-avatar" :style="`background-image:url(${scope.row.imageUrl})`" target="_blank"
                                :href="scope.row.imageUrl"></a>
                            <div class="store-name">
                                <div>{{ scope.row.name }}</div>
                                <el-tag type="danger" size="mini" v-if="!scope.row.isActive">已禁用</el-tag>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="contractName" label="联系人/电话">
                    <template slot-scope="scope">
                        {{ scope.row.contractName }}
                        <span v-if="scope.row.contractTel">
                            <br />{{ scope.row.contractTel }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="agentName" label="代理">
                    <template slot-scope="scope">
                        <a class="tdu" href="javascript:void(0);" @click="queryAgent(scope.row)">{{ scope.row.agentName }}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="deviceCount" label="设备数量"></el-table-column>
                <el-table-column prop="onlineDeviceCount" label="在线设备数量"></el-table-column>
                <el-table-column prop="offlineDeviceCount" label="离线设备数量"></el-table-column>
                <el-table-column prop="orderTurnover" label="营业额" sortable="custom"></el-table-column>
                <el-table-column prop="orderCount" label="订单数" sortable="custom"></el-table-column>
                <el-table-column prop="creationTime" label="创建时间" sortable="custom">
                    <template slot-scope="scope">
                        {{ getFormatDate(scope.row.creationTime, null, 'YYYY/MM/DD') }}
                        <br />
                        {{ getFormatDate(scope.row.creationTime, null, 'HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100px" fixed="right">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <el-button type="primary" plain size="mini">更多
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    @click.native="skipToRoute(`/device/list?id=${scope.row.id}`)">查看商家的设备</el-dropdown-item>
                                <el-dropdown-item
                                    @click.native="skipToRoute(`/merchant/details?id=${scope.row.id}`)">修改/详细</el-dropdown-item>
                                <el-dropdown-item @click.native="computeStatistics(scope.row.id)">更新统计</el-dropdown-item>
                                <el-dropdown-item
                                    @click.native="skipToRoute(`/merchant/record?id=${scope.row.id}`)">查看档案</el-dropdown-item>
                                <el-dropdown-item @click.native="deleteMerchant(scope.row)">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <agent-detail :ref="refAgentDetail"></agent-detail>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import permissions from "@/mixins/permission"
import * as funCommon from "@/api/all"
import * as funAgent from "@/api/agent/index"
import * as funMerchant from "@/api/merchant/index"
import * as appRegion from "@/utils/app_region"
import AgentDetail from "@/views/admin/agent/components/detail"
import moment from "moment"
export default {
    name: "pageMerchantList",
    mixins: [enumConfigs, permissions],
    components: { AgentDetail },
    data() {
        const that = this
        return {
            refAgentDetail: "refMerchantToAgentDetail",
            tableItems: {},
            tableData: [],
            total: null,
            isPagination: false,
            listQuery: {
                Page: 1,
                Size: window.$common.appPageSize,
                CategoryId: "",
                Keywords: "",
                StartDate: null,
                EndDate: null,
                AgentId: "",
                ProvinceCode: "",
                CityCode: "",
                AreaCode: "",
                SortField: null,
                SortDirection: 0,
                IsActive: null,
            },
            selectedTime: [],
            provinceOptions: [],
            cityOptions: [],
            countyOptions: [],
            categories: [],
            agentItems: [],
            merchantAgentArr: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            }
        }
    },
    watch: {
        "listQuery.ProvinceCode"(provinceCode) {
            this.listQuery.CityCode = ""
            this.listQuery.AreaCode = ""
            this.cityOptions = appRegion.getCitys(provinceCode)
            this.countyOptions = []
        },
        "listQuery.CityCode"(cityCode) {
            this.listQuery.AreaCode = ""
            this.countyOptions = appRegion.getCountys(cityCode)
        }
    },
    activated() {
        if (this.listQuery.MemberId != this.$route.query.memberId) {
            this.listQuery.MemberId = this.$route.query.memberId;
            this.readerMemberOptions(this.listQuery.MemberId);
        }
        this.getDataList()
    },
    created() {
        if (this.$route.query.isActive) {
            this.listQuery.IsActive = Boolean(Number(this.$route.query.isActive))
        }

        // 根据参数知道是否需要根据时间搜索 从统计点击过来
        if (this.$route.query.time == "0") {
            this.getTime(0)
        } else if (this.$route.query.time == "1") {
            this.getTime(1)
        } else if (this.$route.query.time == "2") {
            this.getTime(7)
        } else if (this.$route.query.time == "3") {
            this.getTime(30)
        }
        this.renderCategory()
        this.renderAgentOptions("")
        this.provinceOptions = appRegion.getProvinces()
        this.getDataList()
    },
    methods: {
        setQueryTime(timeArr) {
            this.listQuery.StartDate = timeArr[0] || ""
            this.listQuery.EndDate = timeArr[1] || ""
        },
        handleSizeChange(val) {
            this.listQuery.Size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.listQuery.Page = val
            this.getDataList()
        },
        // 多选
        handleSelectionChange(val) {
            this.merchantAgentArr = val
        },
        // 处理传过来的时间数字
        getTime(days) {
            if (days == 1) {
                this.listQuery.StartDate = moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00")
                this.listQuery.EndDate = moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59")
                this.selectedTime = [this.listQuery.StartDate, this.listQuery.EndDate]
            } else {
                this.listQuery.StartDate = moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00")
                this.listQuery.EndDate = moment().format("YYYY-MM-DD 23:59:59")
                this.selectedTime = [this.listQuery.StartDate, this.listQuery.EndDate]
            }
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.listQuery.StartDate = psTime
            this.listQuery.EndDate = peTime
            this.selectedTime = [this.listQuery.StartDate, this.listQuery.EndDate]
        },
        getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            funMerchant.GetMerchantList(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.tableItems = response
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        renderCategory() {
            funCommon.CategoryAll({ type: 2 }).then(res => {
                this.categories = res
            })
        },
        renderAgentOptions(query) {
            funAgent.GetAgentAll({ keywords: query }).then(res => {
                this.agentItems = res
            })
        },
        computeStatistics(id) {
            window.$common.fullLoading()
            funMerchant.ComputeStatisticsInfo({ id }).then(() => {
                window.$common.closeFullLoading()
                this.getDataList()
            })
        },
        sortChange(e) {
            if (e.order) {
                this.listQuery.SortField = e.prop
                if (e.order == "ascending") {
                    this.listQuery.SortDirection = 0
                } else {
                    this.listQuery.SortDirection = 1
                }
            } else {
                this.listQuery.SortDirection = null
                this.listQuery.SortField = null
            }
            this.getDataList()
        },
        deleteMerchant(row) {
            this.confirm(`确认要删除商户【${row.name}】吗？删除前请先回收设备`).then(() => {
                window.$common.fullLoading()
                funMerchant.MerchantDelete({ id: row.id }).then(() => {
                    window.$common.closeFullLoading()
                    this.successMsg("删除成功")
                    this.getDataList();
                })
            })
        },
        queryAgent(row) {
            if (!this.isAgentView) {
                this.warningMsg("无权查看代理代理信息!")
                return false
            }
            let agent = {
                id: row.agentId
            }
            this.$refs[this.refAgentDetail].show(agent)
        },
        _search() {
            this.listQuery.Page = 1
            this.getDataList()
        }
    }
}
</script>