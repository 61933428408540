export default {
    data () {
        return {

        }
    },
    mounted() {
        /*
            ["System", "Products", "Purchase", "Sales", "Stocks", "Materials", 
            "System.Config", "System.RoleManage", "System.AdminManage", "System.LoginLogs", "System.HandleLogs", 
            "Products.CategoryManage", "Products.BrandManage", "Products.ProductView", "Products.ProductCostView", 
            "Products.ProductCreate", "Products.ProductUpdate", "Products.ProductPriceUpdate", "Products.ProductDelete", 
            "Purchase.View", "Purchase.OrderCreate", "Purchase.OrderUpdate", "Purchase.OrderDelete", "Purchase.OrderStockPutIn", 
            "Sales.View", "Sales.OrderCreate", "Sales.OrderUpdate", 
            "Sales.OrderAudit", "Sales.OrderReceiveMoney", "Sales.OrderDelete", "Sales.OrderStockTakeOut", 
            "Stocks.Inventory", "Stocks.BillManage", "Stocks.SerialNumberManage", "Stocks.PutIn", 
            "Stocks.TakeOut", "Stocks.PurchaseReturn", "Stocks.SaleReturn", "Stocks.SaleExchange", "Stocks.ChangeLogs", 
            "Stocks.SerialNumberLogs", "System.WarehouseManage"]
        */
        // console.error(this.$store.getters.roles)
    },
    computed: {
        /* ----------商品---------- */
        isOrder() {
            // 租赁订单
            return this.hasSpecialRole("Permission.Order")
        },
        isOrderView() {
            return this.hasSpecialRole("Permission.Order.View")
        },
        isOrderManage() {
            return this.hasSpecialRole("Permission.Order.Manage")
        },
        isOrderStatistics() {
            return this.hasSpecialRole("Permission.Order.Statistics")
        },

        isFinancial() {
            // 财务统计
            return this.hasSpecialRole("Permission.Financial")
        },
        isFinancialStatistics() {
            return this.hasSpecialRole("Permission.Financial.Statistics")
        },

        isDevice() {
            // 设备管理
            return this.hasSpecialRole("Permission.Device")
        },
        isDeviceTypeView() {
            return this.hasSpecialRole("Permission.Device.TypeView")
        },
        isDeviceTypeManage() {
            return this.hasSpecialRole("Permission.Device.TypeManage")
        },
        isDeviceView() {
            return this.hasSpecialRole("Permission.Device.View")
        },
        isDeviceManage() {
            return this.hasSpecialRole("Permission.Device.Manage")
        },
        isDeviceInstruct() {
            return this.hasSpecialRole("Permission.Device.Instruct")
        },
        isDeviceUpgrade() {
            return this.hasSpecialRole("Permission.Device.Upgrade")
        },
        isDeviceCollect() {
            return this.hasSpecialRole("Permission.Device.Collect")
        },
        isDeviceReturn() {
            return this.hasSpecialRole("Permission.Device.Return")
        },
        isDeviceLay() {
            return this.hasSpecialRole("Permission.Device.Lay")
        },
        isDeviceRecovery() {
            return this.hasSpecialRole("Permission.Device.Recovery")
        },

        isMerchant() {
            // 商家管理
            return this.hasSpecialRole("Permission.Merchant")
        },
        isMerchantCategoryView() {
            return this.hasSpecialRole("Permission.Merchant.CategoryView")
        },
        isMerchantCategoryManage() {
            return this.hasSpecialRole("Permission.Merchant.CategoryManage")
        },
        isMerchantView() {
            return this.hasSpecialRole("Permission.Merchant.View")
        },
        isMerchantManage() {
            return this.hasSpecialRole("Permission.Merchant.Manage")
        },

        isAgent() {
            // 代理管理
            return this.hasSpecialRole("Permission.Agent")
        },
        isAgentView() {
            return this.hasSpecialRole("Permission.Agent.View")
        },
        isAgentManage() {
            return this.hasSpecialRole("Permission.Agent.Manage")
        },
        isAgentTaskAmountLogs() {
            return this.hasSpecialRole("Permission.Agent.TaskAmountLogs")
        },
        isAgentLevelLogs() {
            return this.hasSpecialRole("Permission.Agent.LevelLogs")
        },

        isMember() {
            // 成员管理
            return this.hasSpecialRole("Permission.Member")
        },
        isMemberView() {
            return this.hasSpecialRole("Permission.Member.View")
        },
        isMemberManage() {
            return this.hasSpecialRole("Permission.Member.Manage")
        },
        isMemberBalanceLogs() {
            return this.hasSpecialRole("Permission.Member.BalanceLogs")
        },
        isMemberFrozenLogs() {
            return this.hasSpecialRole("Permission.Member.FrozenLogs")
        },
        isMemberFeedback() {
            return this.hasSpecialRole("Permission.Member.Feedback")
        },

        isArticle() {
            // 文章管理
            return this.hasSpecialRole("Permission.Article")
        },
        isArticleView() {
            return this.hasSpecialRole("Permission.Article.View")
        },
        isArticleManage() {
            return this.hasSpecialRole("Permission.Article.Manage")
        },

        isAd() {
            // 广告管理
            return this.hasSpecialRole("Permission.Ad")
        },
        isAdManage() {
            return this.hasSpecialRole("Permission.Ad.Manage")
        },

        isSystem() {
            // 系统管理
            return this.hasSpecialRole("Permission.System")
        },
        isSystemRoleManage() {
            return this.hasSpecialRole("Permission.System.RoleManage")
        },
        isSystemAdminManage() {
            return this.hasSpecialRole("Permission.System.AdminManage")
        },
        isSystemSystemConfig() {
            return this.hasSpecialRole("Permission.System.SystemConfig")
        },
        isSystemLoginLogs() {
            return this.hasSpecialRole("Permission.System.LoginLogs")
        },
        isSystemHandleLogs() {
            return this.hasSpecialRole("Permission.System.HandleLogs")
        },

        isClientConfig() {
            // 客户端配置
            return this.hasSpecialRole("Permission.ClientConfig")
        },
        isClientConfigConfig() {
            return this.hasSpecialRole("Permission.ClientConfig.Config")
        },

        isOperation() {
            // 运营端管理
            return this.hasSpecialRole("Permission.Operation")
        },
        isOperationAgentStatistics() {
            return this.hasSpecialRole("Permission.Operation.AgentStatistics")
        },
        isOperationMerchantStatistics() {
            return this.hasSpecialRole("Permission.Operation.MerchantStatistics")
        },
        isOperationDeviceView() {
            return this.hasSpecialRole("Permission.Operation.DeviceView")
        },
        isOperationDeviceInstruct() {
            return this.hasSpecialRole("Permission.Operation.DeviceInstruct")
        },
        isOperationDeviceCollect() {
            return this.hasSpecialRole("Permission.Operation.DeviceCollect")
        },
        isOperationDeviceReturn() {
            return this.hasSpecialRole("Permission.Operation.DeviceReturn")
        },
        isOperationDeviceLay() {
            return this.hasSpecialRole("Permission.Operation.DeviceLay")
        },
        isOperationDeviceRecovery() {
            return this.hasSpecialRole("Permission.Operation.DeviceRecovery")
        },
        isOperationDeviceTest() {
            return this.hasSpecialRole("Permission.Operation.DeviceTest")
        },
    },
    methods: {
        // 传递权限名称，返回是否存在
        isHasPermission(roleName) {
            let index = this.$store.getters.roles.findIndex(role => {
                role = role.replace(".", "")
                roleName = roleName.replace(".", "")
                return role == roleName
            })
            return index != -1
        },
        // 判断是否有指定权限
        hasSpecialRole(role) {
            return this.$store.getters.roles.indexOf(role) != -1
        }
    }
  }
  
  
  